<template>
  <section class="navbar-free">
    <Transition name="slide-fade">
      <div v-if="isMenuOpen" class="navbar-free__overlay" @click="isMenuOpen = false">
        <div class="navbar-free__overlay__lateral-options">
          <div v-if="isLoggedIn || isGS1" class="navbar-free__overlay__lateral-options__user">
            <span>
              <img src="/icons/icn_user_v2.svg" alt="user" />
            </span>
            <span v-if="isLoggedIn">{{ user.name }}</span>
            <span v-else>{{ isGS1Account }}</span>
          </div>
          <div class="navbar-free__overlay__lateral-options__items general-options">
            <template v-for="(option, index) in availableMenuOptions">
              <div
                v-if="option.isB2C ? isB2CUser : true"
                class="option"
                :key="index"
                @click="onClickOption(option.sectionRef, option.route)"
              >
                <div class="logo">
                  <img :src="option.logo" :alt="option.title" />
                </div>
                <div class="title">{{ option.title }}</div>
              </div>
            </template>
            <div v-if="isLoggedIn || isGS1" @click.prevent.stop="onClickLogout" class="option">
              <div class="logo">
                <img src="/icons/icn_sign_out.svg" alt="signout" />
              </div>
              <div class="title">Cerrar Sesión</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <nav class="navbar-free__nav is-fixed-top">
      <RouterLink class="navbar-free__nav__brand" :to="logoLink">
        <img
          :src="displayWidth > 768 ? '/images/logo_golstats_horizontal_white.svg' : '/images/logo-golstats-shield.svg'"
          alt="GolStats"
        />
      </RouterLink>
      <div class="navbar-free__nav__tournament">
        <NavBarTournamentButton v-if="isTournamentButtonVisible" />
      </div>
      <div v-if="!isLoggedIn && !isGS1" class="navbar-free__nav__options">
        <span class="contact-option" @click="onClickContact">
          <span class="flag-movil-contact-text">Contáctanos</span>
          <span> <img src="/assets/icons/contacto.png" class="contact-icon" alt="user" /> </span
        ></span>
        <a class="navbar-free__nav__options__create-account is-uppercase" @click="onClickRegister">
          Crear Cuenta
          <div>
            <div class="is-lowercase">$ <span class="amount">99</span> al mes</div>
          </div>
        </a>
        <a class="is-uppercase" @click="onClickLogin">Iniciar Sesión</a>
      </div>
      <div class="navbar-free__nav__menu">
        <span v-if="isLoggedIn || isGS1" class="navbar-free__nav__menu__contact-option" @click="onClickContact">
          <span class="navbar-free__nav__menu__flag-movil-contact-text">Contáctanos</span>
          <span> <img src="/assets/icons/contacto.png" class="navbar-free__nav__menu__contact-icon" alt="user" /> </span
        ></span>
        <!--        <div v-if="isGS1" class="navbar-free__nav__menu__profile" @click="openProfile">-->
        <!--          Ir a tu perfil de Profesional-->
        <!--        </div>-->
        <template v-if="isLoggedIn || isGS1">
          <div class="navbar-free__nav__menu__text" @click="onMenuClicked">Menu</div>
          <div class="navbar-free__nav__menu__hamburger" @click="onMenuClicked">
            <div />
            <div />
            <div />
          </div>
        </template>
      </div>
    </nav>
    <div class="navbar-free__stadium" v-if="isStadiumVisible">
      <!-- <BarVideoMain /> -->
      <div class="navbar-free__stadium__header">
        <div>
          <RouterLink class="navbar-free__stadium__header__brand" :to="{ name: 'main' }">
            <img src="/images/logo_golstats_horizontal_white.svg" alt="GolStats" />
          </RouterLink>
        </div>
      </div>

      <div class="navbar-free__stadium__image">
        <h4>Estadísticas <span>GolStats</span></h4>
        <img src="/images/logo-golstats-shield.svg" alt="Logo" />
      </div>
    </div>
    <Confirm
      question="¿Estás seguro que deseas cerrar tu sesión?"
      :showModalConfirm="showModalConfirm"
      @onConfirm="logout"
      @onCancel="closeModalConfirm"
    />
  </section>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';
import { ProfileSidebarGoTo, clickCrearCuentaPremium, clickLogin, clickBtnLogout } from '@/utils/analytics';
// import BarVideoMain from '@/components/Sections/Main/BarVideoMain';
import { getIsGS20 } from '@/utils/misc';

export default {
  name: 'NavBarFreeMain',
  components: {
    Confirm: () => import('@/components/Modals/Confirm'),
    NavBarTournamentButton: () => import('@/components/Elements/NavBarTournamentButton'),
    // BarVideoMain,
  },
  props: {
    isStadiumVisible: {
      type: Boolean,
      default: false,
    },
    widgetTeam: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [{ title: 'Ajustes', icon: '/icons/icn-ajustes.svg', to: 'payment', event: 'click_btn_profile' }],
      itemsNav: [{ title: 'Home', icon: '/icons/icn_home.svg', to: 'main', event: 'home' }],
      selected: null,
      showModalConfirm: false,
      isMenuOpen: false,
      arrayLandingRoutesNames: [
        'viewAll',
        'extendedCalendar',
        'table',
        'golStatsData',
        'topsSection',
        'teams',
        'playerTeams',
        'players',
        'playerProfile',
        'teamProfile',
      ],
    };
  },
  computed: {
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapState(['isSectionLoading', 'displayWidth']),
    ...mapGetters('loginGeneral', [
      'isB2CUser',
      'isLoggedIn',
      'getPremiumAccount',
      'isGS1',
      'urlGS1',
      'getIsGolstats',
      'isGS1Account',
    ]),
    ...mapState('loginGeneral', ['user', 'pymErr']),
    menuOptions() {
      if (this.arrayLandingRoutesNames.includes(this.$route.name)) {
        return [
          {
            title: 'Ajustes',
            logo: '/icons/icn-ajustes.svg',
            route: { to: 'payment' },
            isB2C: true,
          },
        ];
      } else {
        return [
          {
            title: 'Ajustes',
            logo: '/icons/icn-ajustes.svg',
            route: { to: 'payment' },
            isB2C: true,
          },
          {
            title: 'Datos GS',
            logo: '/images/menu/icn-datos-gs@2x.png',
            sectionRef: 'datosGS',
          },
          {
            title: 'Apostador',
            logo: '/images/menu/icn-apostadores@2x.png',
            sectionRef: 'apostadorGS',
          },
          {
            title: 'Mejores Jugadores',
            logo: '/images/menu/icn-mejores-jugadores@2x.png',
            sectionRef: 'mejoresGS',
          },
          {
            title: 'Calendario',
            logo: '/images/menu/icn-previas@2x.png',
            sectionRef: 'previasGS',
          },
          {
            title: 'LIVE',
            logo: '/images/menu/icn_live.svg',
            sectionRef: 'liveGS',
          },
          {
            title: 'Tabla General',
            logo: '/images/menu/icn-tabla-general@2x.png',
            sectionRef: 'tablaGS',
          },
        ];
      }
    },
    isTournamentButtonVisible() {
      return (
        this.arrayLandingRoutesNames.includes(this.$route.name) &&
        !['players', 'playerProfile', 'teamProfile'].includes(this.$route.name)
      );
    },
    availableMenuOptions() {
      if (this.$route.meta.isMenuHidden || (this.$route.name === 'widget' && this.colorsConfig.type === 13)) {
        return [this.menuOptions[0]];
      } else {
        return this.menuOptions;
      }
    },
    showNav() {
      return this.displayWidth < 1210;
    },
    logoLink() {
      return {
        name: 'viewAll',
      };
    },
  },
  methods: {
    ...mapMutations(['SET_MENU_DRAWER', 'setIsSectionLoading']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapActions('loginGeneral', ['doLogout', 'doLoginGS2']),
    ...mapMutations('general', ['setSectionAnalytics', 'setSectionRef']),
    ...mapMutations('leagueGeneral', ['setBooleanIsTournamentsModalOpen']),
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    async logout() {
      this.setIsSectionLoading(true);
      try {
        await this.doLogout({ is3pvLogout: true });
        // if (this.$route.path.startsWith('/')) {
        //   this.$router.go();
        // }
        // todo: check if this call is required
        // await this.fetchTournamentsWithScenarios(1);
      } finally {
        this.isMenuOpen = false;
        this.setIsSectionLoading(false);
      }
    },
    onClickProfessional() {
      window.open('https://info.golstats.com/#professional');
    },
    onClickAmateur() {
      window.open('https://debuta.mx/');
    },
    onClickAmbassadors() {
      window.open('https://info.golstats.com/#ambassadors');
    },
    onClickMedia() {
      window.open('https://info.golstats.com/#media');
    },
    onClickSports() {
      window.open('https://info.golstats.com/#sports');
    },
    onClickTechnology() {
      window.open('https://info.golstats.com/#partners');
    },
    goTo(route) {
      ProfileSidebarGoTo(route.event, this.getPremiumAccount, 'menu_lateral', this.getIsGolstats);
      if (route.to !== router.currentRoute.name) {
        const location = { name: route.to };
        if (route.to === 'widget') {
          location.params = { team: localStorage.getItem('widget-team') };
        }
        router.push(location);
      }
    },
    onClickOption(sectionRef, route) {
      if (sectionRef) {
        this.setSectionRef(sectionRef);
      } else if (route) {
        this.goTo(route);
      }
    },
    onClickContact() {
      window.open('/contact-main');
    },
    onClickRegister() {
      this.setSectionAnalytics('Banner_Home_Widget');
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        section: 'menu_lateral',
        banner: 'Banner_Home_Widget',
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
    onClickLogin() {
      clickLogin({ is_premium: this.getPremiumAccount, section: 'menu_lateral', golstats: this.getIsGolstats });
      this.SET_AUTH_COMPONENT('LoginMain');
    },
    onClickLogout() {
      clickBtnLogout(this.getPremiumAccount, this.getIsGolstats);
      this.showModalConfirm = true;
    },
    onMenuClicked() {
      if (!this.isMenuOpen) {
        this.setBooleanIsTournamentsModalOpen(false);
      }
      this.isMenuOpen = !this.isMenuOpen;
      // this.$router.push({ name: 'payment-methods' });
    },
    openProfile() {
      // window.open(this.urlGS1);
      window.location = this.urlGS1;
    },
  },
  async mounted() {
    try {
      const isGS2User = window.localStorage.getItem('isGS2User');
      let sysType = window.localStorage.getItem('sysType');
      const userEncoded = window.localStorage.getItem('gs2User');
      const isGS1User = window.localStorage.getItem('isGS1User');
      const gs1User = window.localStorage.getItem('gs1UserP1');
      const gs1Pass = window.localStorage.getItem('gs1UserP2');
      if (isGS2User && userEncoded && this.$route.name !== 'gsLogout') {
        let baseUrl = process.env.VUE_APP_GS20_URL;
        if (!sysType) {
          const gsResponse = await this.doLoginGS2({ login: JSON.parse(window.atob(userEncoded)) });
          if (gsResponse && gsResponse.user_type === 1) {
            window.localStorage.setItem('sysType', gsResponse.type_system);
            sysType = gsResponse.type_system;
          }
        }
        baseUrl = getIsGS20(sysType) ? process.env.VUE_APP_GS20_URL : process.env.VUE_APP_GS30_URL;
        window.location = `${baseUrl}/login/?user=${userEncoded}`;
      } else if (isGS1User && gs1User && gs1Pass) {
        window.location.replace('https://professional.golstats.com/login2/' + gs1User + '/' + gs1Pass);
      }
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>

<style scoped lang="scss">
$backgroundColor: #242424;
$borderColor: #e0e0e0;
.navbar-free {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display: flex;
    justify-content: flex-end;
    padding-top: 61px;
    overflow: auto;
    transition: width ease 0.5s;

    &__lateral-options {
      width: 300px;
      min-height: 100%;
      height: fit-content;
      background-color: white;

      &__user {
        font-family: Circular-Std-Bold, sans-serif;
        font-size: 2rem;
        letter-spacing: -0.17px;
        color: #494a4b;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-bottom: solid 1px $borderColor;

        & span:first-child {
          margin-right: 16px;
          width: 50px;
        }

        & span:last-child {
          text-overflow: ellipsis;
        }
      }

      &__new-user {
        padding: 1rem 2.5rem;
        border-bottom: solid 1px $borderColor;
      }

      &__items2 {
        width: 100%;
        display: flex;
        padding: 0 0px;
        flex-direction: column;
        align-items: center;
        & div.item-header {
          background-color: #f9f9f9;
          width: 100%;
          font-family: 'Circular-Std-Bold';
          height: 90px;
          text-align: left;
          padding: 35px 55px;
          font-size: 1.425rem;
          letter-spacing: -0.1px;
        }

        & div.item {
          width: 100%;
          cursor: pointer;
          padding: 15px 55px;
          font-size: 1.125rem;
          letter-spacing: -0.1px;
          color: #494a4b;
          text-align: left;
          display: flex;
          align-items: center;
          border-bottom: solid 1px $borderColor;

          & .icon {
            margin-right: 6px;
          }

          &:hover {
            background-color: #dbdbdb;
          }
        }
      }

      &__items {
        width: 100%;
        display: flex;
        padding: 0 55px;
        flex-direction: column;
        align-items: center;

        &.general-options {
          padding: 0 1.2rem;

          & div.option {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px dotted #cfdce2;
            cursor: pointer;

            &:last-child {
              border-bottom: unset;
            }

            & .title {
              margin: 1.875rem 0;
              font-family: Roboto-Medium, sans-serif;
              font-size: 1.25rem;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.9;
              letter-spacing: 0.2px;
              text-align: left;
              color: #132839;
            }

            & .logo {
              height: 2rem;
              width: auto;
              margin: 0 0.8rem;

              & > img {
                height: 2rem;
                width: auto;
              }
            }
          }
        }

        & div.item {
          width: 100%;
          cursor: pointer;
          padding: 15px;
          font-size: 1.125rem;
          letter-spacing: -0.1px;
          color: #494a4b;
          text-align: left;
          display: flex;
          align-items: center;
          border-bottom: solid 1px $borderColor;

          & .icon {
            margin-right: 6px;
          }

          &:hover {
            background-color: #dbdbdb;
          }
        }
      }
    }
  }
  &__nav {
    z-index: 35;
    min-height: 54px;
    background-color: $backgroundColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 6px 6px 35px;
    position: relative;
    height: 60px;
    color: white;

    &__brand {
      position: absolute;
      top: 9px;
      left: 8%;
      & img {
        max-height: 2.5rem;
        position: relative;
        left: -50% !important;
      }
    }

    &__tournament {
      margin-right: 1em;

      @media screen and (max-width: 566px) {
        display: none;
      }
    }

    &__options {
      display: flex;
      position: relative;
      &__create-account {
        position: relative;

        & > div {
          padding: 0.2rem 0.8rem 0;
          font-size: 0.9rem;
          background-color: #1a83f6;
          position: absolute;
          transform: skew(-25deg);
          bottom: -1rem;
          left: 1.6rem;
          box-shadow: 2px 2px 3px black;
          @media screen and (max-width: 370px) {
            padding: 0.2rem 0.4rem 0;
          }
          @media screen and (max-width: 350px) {
            padding: 0.2rem 0.2rem 0;
          }
          & > div {
            font-family: 'Avenir-Medium' !important;
            transform: skew(25deg);
            & > .amount {
              font-family: 'Avenir-Pro-Bold' !important;
              font-weight: bold !important;
            }
          }
        }
      }

      & a {
        margin: 0 5px;
        color: white;
        border-radius: 17px;
        border: solid 0.9px #ffffff;
        padding: 6px 18px;
        font-family: Circular-Std, sans-serif;
      }
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & div {
        margin: 0 4px;
      }

      &__text {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-family: Circular-Std;
        @media screen and (max-width: 540px) {
          display: none;
        }
      }

      &__profile {
        width: 248px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 16px;
        background-color: #73d44e;
        @media screen and (max-width: 380px) {
          width: 208px;
          font-size: 13px;
        }
      }

      &__contact-option {
        font-size: 14px !important;
        margin-right: 15px;
        @media screen and (max-width: 768px) {
          font-size: 11px !important;
        }
        @media screen and (max-width: 370px) {
          margin-right: 4px;
        }
      }

      &__flag-movil-contact-text {
        @media screen and (max-width: 540px) {
          display: none;
        }
      }

      &__contact-icon {
        width: 26px;
        float: right;
        margin-left: 9px;
        margin-top: 2px;
        @media screen and (max-width: 768px) {
          margin-top: 0px;
        }
        //@media screen and (max-width: 540px) {
        //  width: 30px;
        //  margin-top: -2px;
        //  margin-right: 165px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 518px) {
        //  margin-right: 145px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 500px) {
        //  margin-right: 125px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 475px) {
        //  margin-right: 105px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 455px) {
        //  margin-right: 85px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 435px) {
        //  margin-right: 65px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 406px) {
        //  margin-right: 40px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 390px) {
        //  margin-right: 27px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 370px) {
        //  margin-right: 44px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 358px) {
        //  margin-right: 30px;
        //  margin-left: -15px;
        //}
        //@media screen and (max-width: 336px) {
        //  margin-right: 15px;
        //  margin-left: -15px;
        //}
      }

      &__hamburger {
        padding: 10px 12px;
        border-radius: 4px;

        &:hover {
          background-color: #4d4d4d;
        }

        & div {
          width: 16px;
          height: 1px;
          background-color: white;
          margin: 4px 0;
        }
      }
    }
  }

  &__stadium {
    &__header {
      display: none;
      margin-top: 0px;
      height: 48px;
      background-color: $backgroundColor;
      position: relative;

      &__brand {
        position: absolute;
        top: 4px;
        left: 50%;
        & img {
          max-height: 2.5rem;
          position: relative;
          left: -50% !important;
        }
      }
    }

    &__image {
      width: 100%;
      background-color: black;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('https://az755631.vo.msecnd.net/golstats-bets/header-desktop-stadium.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      object-position: center;
      object-fit: cover;
      @media screen and (max-width: 576px) {
        height: 140px;
        background-image: url('/images/header-mobile-stadium.png');
      }
      @media screen and (max-width: 410px) {
        height: 83px;
      }
      h4 {
        color: #ffffff;
        font-size: 42px;
        font-weight: 500;
        font-family: Circular-Std-Book, sans-serif;
        margin: 0 12px 0 0;
        text-shadow: -1px 1px 2px #000000;
        @media screen and (max-width: 500px) {
          font-size: 32px;
        }
        @media screen and (max-width: 410px) {
          font-size: 24px;
        }
      }
      span {
        font-family: Circular-Std-Bold, sans-serif;
      }
      img {
        height: 42px;
        @media screen and (max-width: 500px) {
          height: 32px;
        }
        @media screen and (max-width: 410px) {
          height: 24px;
        }
      }
    }
  }
}

a.btn {
  display: block;
  width: 100%;
  margin: 12px 0;
  padding: 10px 0;
  color: #3c3c3c;
  border: solid 2px #3c3c3c;
  border-radius: 28px;

  &.btn-login {
    position: relative;
    & span:first-child {
      margin-right: 20px;
    }
    & span:last-child {
      position: absolute;
      top: 2px;
      right: 5px;
    }
  }
}

.btn-create-account {
  position: relative;
  & > div {
    padding: 0.2rem 0.8rem 0;
    background-image: linear-gradient(to right, #ae0b0b, #e02020);
    position: absolute;
    transform: skew(-25deg);
    bottom: -14px;
    right: 60px;
    box-shadow: 2px 2px 3px black;

    & > div {
      color: white;
      font-size: 12px;
      font-family: 'Avenir-Medium' !important;
      transform: skew(25deg);
      & > .amount {
        font-family: 'Avenir-Pro-Bold' !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .navbar-free__nav {
    &__brand {
      left: 10%;
    }
  }
}

@media screen and (max-width: 926px) {
  .navbar-free__nav {
    &__brand {
      display: none;
    }
  }
}

@media screen and (max-width: 832px) {
  .navbar-free__nav {
    &__options {
      &__create-account {
        position: relative;

        & > div {
          bottom: -0.8rem;
          left: 0.8rem;

          & > div {
            font-size: 0.6rem;
          }
        }
      }

      & a {
        padding: 4px 14px;
        font-size: 0.6rem;
      }
    }
  }

  .navbar-free__stadium {
    &__header {
      display: block;
    }
  }
}
@media screen and (max-width: 654px) {
  .navbar-free__nav {
    padding: 6px;
    & .flag-movil-contact-text {
      display: none;
    }
  }
}
//@media screen and (max-width: 500px) {
//  .navbar-free__nav {
//    &__options {
//      width: 100%;
//    }
//  }
//}
@media screen and (max-width: 370px) {
  //.navbar-free__nav {
  //  &__options {
  //    width: 100%;
  //    &__create-account {
  //      & > div {
  //        bottom: -0.8rem;
  //        left: 0.2rem;
  //
  //        & > div {
  //          font-size: 0.6rem;
  //        }
  //      }
  //    }
  //    & a {
  //      padding: 7px 7px;
  //      font-size: 0.45rem;
  //    }
  //  }
  //}
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.contact-option {
  font-size: 14px !important;
  margin-top: 8px;
  margin-right: 15px;
  @media screen and (max-width: 768px) {
    margin-top: 4px;
    font-size: 11px !important;
  }
  @media screen and (max-width: 370px) {
    margin-right: 4px;
  }
}
.contact-option:hover {
  cursor: pointer;
  opacity: 0.8;
}
.contact-icon {
  width: 26px;
  float: right;
  margin-left: 9px;
  margin-top: 2px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
  //@media screen and (max-width: 500px) {
  //  width: 30px;
  //  margin-top: -2px;
  //  margin-right: 140px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 488px) {
  //  margin-right: 125px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 472px) {
  //  margin-right: 105px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 452px) {
  //  margin-right: 90px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 436px) {
  //  margin-right: 72px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 420px) {
  //  margin-right: 58px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 406px) {
  //  margin-right: 40px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 390px) {
  //  margin-right: 27px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 376px) {
  //  margin-right: 15px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 370px) {
  //  margin-right: 85px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 358px) {
  //  margin-right: 65px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 336px) {
  //  margin-right: 45px;
  //  margin-left: -15px;
  //}
  //@media screen and (max-width: 316px) {
  //  margin-right: 25px;
  //  margin-left: -15px;
  //}
}
</style>
